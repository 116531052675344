<template>
  <b-container fluid>
    <b-card title="Cedentes">
      <assignor-table
        :data="data.assignors.map(item => ({
          ...item,
          ...item.assignor
        }))"
      />
    </b-card>

    <b-card title="Grupos de Cedente">
      <assignor-table
        :clickable="true"
        :data="data.groups"
        @click-row="onClickRowGroup"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer,
    BCard,
} from 'bootstrap-vue';

import AssignorTable from './Table.vue';
import AssignorService from '@/service/assignor/assignor';

export default {

    components: {
        AssignorTable,
        BContainer,
        BCard,
    },
    data() {
        return {
            data: {
                groups: [],
                assignors: [],
            },
        };
    },

    computed: {
        assignorsSorted() {
            const assignors = JSON.parse(JSON.stringify(this.data));
            const filterInLowerCase = this.filter.toLowerCase();
            return assignors.filter(item => item.assignor.name.toLowerCase().includes(filterInLowerCase)).sort((a, b) => a.assignor.name.localeCompare(b.assignor.name));
        },
    },

    async mounted() {
        this.callLoading(true);
        const response = await AssignorService.getByDate();
        this.data = response.data.data;
        this.callLoading(false);
    },

    methods: {
        onClickRowGroup(data) {
            this.$router.push(`/cedente/${data.group_id}`);
        },
    },
};

</script>
